import React from 'react';
import Helmet from 'react-helmet';

function GAMETA() {
	return (
		<Helmet>
			<meta
				name="google-site-verification"
				content="Hb1TYLPvE52CfLvUnSAlL57Ux9_qHojHqgfFlt4z_Lw"
			/>
		</Helmet>
	);
}

export default GAMETA;
