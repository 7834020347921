import React from 'react';

import Layout from '../components/layout';
// import Listing from '../components/listing';
import SEO from '../components/seo';
import GAMETA from '../components/ga-meta';
// import styled from 'styled-components';
import MainTitleBlock from '../components/mainTitleBlock';

import { MainWrapper } from '../components/headings';

// const SubHead = styled.p`
// 	color: darkslategray;
// 	font-weight: 600;
// 	text-align: center;
// `;

const IndexPage = ({ location }) => (
	<Layout location={location}>
		<MainWrapper>
			{/* <GAMETA></GAMETA> */}
			<SEO
				title="The official web site for Travis Bredehoft"
				keywords={[
					'gatsby',
					'fort collins front-end developer',
					'fort collins designer',
					'colorado web design',
					'colorado front-end developer',
					'fort collins graphic design',
				]}
			/>
			<MainTitleBlock />
			{/* <MainTitle id="travis">I am Travis Bredehoft</MainTitle>
			<SubHead>A Colorado designer, developer, and Fly Fisher.</SubHead> */}
			{/* <Listing /> */}
		</MainWrapper>
	</Layout>
);

export default IndexPage;
