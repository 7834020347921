import React, { Component } from 'react';

import styled from 'styled-components';
import { MainTitle } from '../components/headings';

const SubHead = styled.p`
	color: darkslategray;
	font-weight: 800;
	text-align: center;
	font-family: Arimo, sans-serif;
	mix-blend-mode: color-burn;
	user-select: none;
	cursor: crosshair;
`;

const NameSpan = styled.span`
	color: #fff;
	display: inline-block;
	font-weight: 300;
	text-align: center;
	will-change: transform;
`;

export default class MainTitleBlock extends Component {
	constructor(props) {
		super(props);
		this.namePlate = React.createRef();
		this.firstName = React.createRef();
		this.lastName = React.createRef();
		this.state = {
			pos: { x: 0, y: 0 },
			shadow: true,
		};

		this.onMouseMove = this.onMouseMove.bind(this);
	}

	componentDidMount() {
		if (location.pathname === '/') {
			document.addEventListener('mousemove', this.onMouseMove);
		}
	}
	componentWillUnmount() {
		document.removeEventListener('mousemove', this.onMouseMove);
	}

	onMouseMove(e) {
		this.setState({
			pos: {
				x: e.pageX,
				y: e.pageY,
			},
		});

		this.CreateShadow();
	}

	CreateShadow() {
		if ('ontouchstart' in window == false && this.state.shadow) {
			let [moveX, moveY] = [this.state.pos.x / -100, this.state.pos.y / -120];
			let [NamePlate, FirstName, LastName] = [
				this.namePlate,
				this.firstName,
				this.lastName,
			];
			// console.log('NP', NamePlate.current.style);

			FirstName.current.style.transform = `translate3d(${moveX /
				2}px, ${moveY}px, 0)`;
			LastName.current.style.transform = `translate3d(${moveX /
				2}px, ${moveY}px, 0)`;
			NamePlate.current.style.textShadow = `${moveX}px ${-moveY}px 2px rgba(25, 25, 25, 0.25)`;
		}
	}

	render() {
		return (
			<div>
				{/* <div onMouseMove={this.onMouseMove.bind(this)}> */}
				<MainTitle ref={this.namePlate}>
					I am <NameSpan ref={this.firstName}>Travis</NameSpan>{' '}
					<NameSpan ref={this.lastName}>Bredehoft</NameSpan>.
				</MainTitle>
				<SubHead>A Colorado designer, developer, and Fly Fisher.</SubHead>
			</div>
		);
	}
}
